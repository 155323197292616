/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyAvQF7QY5v7z3hwaA3faSYL_YaF0ji2c6Q",
  "appId": "1:1019573972475:web:6fe6fa6a8f18723422ef5e",
  "authDomain": "schooldog-i-madison-ga.firebaseapp.com",
  "measurementId": "G-5H1WMJVGFK",
  "messagingSenderId": "1019573972475",
  "project": "schooldog-i-madison-ga",
  "projectId": "schooldog-i-madison-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-madison-ga.appspot.com"
}
